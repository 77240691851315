import { Link } from 'react-router-dom';
import { IoArrowUndoCircleOutline } from 'react-icons/io5';
import { BiLogoTelegram, BiLogoWhatsapp } from 'react-icons/bi';
import Menu from '../UI/Menu';
import styles from './About.module.css';

const About = () => {
  return (
    <div className={styles.container}>
      <Link to="/">
        <Menu icon={<IoArrowUndoCircleOutline />}></Menu>
      </Link>
      <article>
        <h1>about this app</h1>
        <p>
          This app uses your browser's local storage to store workout
          information. I haven't connected any external databases or cookies.
          So, all the data is stored in your device's browser memory.
          <br /> If you want to transfer the program to another device, I've
          added a QR code feature.
        </p>
        <p>
          <span>Display QR</span> option shows the current status of your
          workout program and updates automatically when you make changes.
        </p>
        <p>
          <span>Scan QR</span> function recognizes and loads the program from
          the QR into your app's memory, updating the current status
          accordingly.
        </p>
        <p>
          The application is designed for use on mobile phones or tablets. I
          haven't adapted the design for PCs or laptops.
        </p>
        <p>
          If you have any <span className={styles.underline}>questions</span> or{' '}
          <span className={styles.underline}>suggestions</span>, please contact
          me via:
        </p>
        <ul>
          <a className={styles.link} href="https://t.me/londort" target="blank">
            <BiLogoTelegram />
            <li>Telegram</li>
          </a>
          <a
            className={styles.link}
            href="https://wa.me/393755572777"
            target="blank"
          >
            <BiLogoWhatsapp />
            <li>WhatsApp</li>
          </a>
        </ul>
      </article>
    </div>
  );
};

export default About;
